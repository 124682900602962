import React from 'react';
import { Link } from 'react-router-dom';
import SimpleContact from '../element/simple-contact';

import pdf1 from "../../assets/pdf/pdf-1.pdf"
import pdf2 from "../../assets/pdf/pdf-2.pdf"
import pdf3 from "../../assets/pdf/pdf-3.pdf"
import pdf4 from "../../assets/pdf/pdf-4.pdf"
import pdf5 from "../../assets/pdf/pdf-5.pdf"
import pdf6 from "../../assets/pdf/pdf-6.pdf"

import technology_img from "../../assets/images/technology/tolerancia.jpg"

const ourtechnologybg = require('./../../assets/images/background/ourtechnologybg.jpg');

function OurTechnology() {

    return (
        <>
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + ourtechnologybg + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Nossa tecnologia</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/#'}>Home</Link></li>
                        <li className="active">Nossa Tecnologia</li>
                    </ul>
                </div>
            </section>

            <section className="services-section-three">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <div className="sub-title">Especificações</div>
                        <h2>Tolerância</h2>
                    </div>
                    <div className='w-100 d-flex justify-content-center align-center'>
                    <img src={technology_img} alt='Tabela de tolerâncias GCI' />
                    </div>
                </div>
            </section>

            <section className="services-section-three">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <div className="sub-title">Manuais</div>
                        <h2>Baixe nossos Manuais</h2>
                    </div>
                    <div className="row">
                        {/* <!-- Services Block One --> */}
                        <a href={pdf1} download="Manual 1 - Accel e Pcad" className="service-block-one col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="inner-box">
                                <div className="icon">
                                    <span className="bi bi-filetype-pdf">
                                    </span>
                                </div>
                                <h3 className='text-dark'>Manual 1 - Accel e Pcad</h3>
                                <div className="text text-dark">Com o Manual 01 você aprende a gerar os arquivos Gerbers nos programas Accel eda e PCAD2001.</div>
                            </div>
                        </a>
                        <a href={pdf2} download="Manual 2 - Eagle" className="service-block-one col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="inner-box">
                                <div className="icon">
                                    <span className="bi bi-filetype-pdf">
                                    </span>
                                </div>
                                <h3 className='text-dark'>Manual 2 - Eagle</h3>
                                <div className="text text-dark">Com o Manual 02 você aprende a gerar arquivos GERBER no programa EAGLE.</div>
                            </div>
                        </a>
                        <a href={pdf3} download="Manual 3 - WindBoard" className="service-block-one col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="inner-box">
                                <div className="icon">
                                    <span className="bi bi-filetype-pdf">
                                    </span>
                                </div>
                                <h3 className='text-dark'>Manual 3 - WindBoard</h3>
                                <div className="text text-dark">Com o Manual 01 você aprende a gerar os arquivos GERBER com o programa WinBoard.</div>
                            </div>
                        </a>
                        <a href={pdf4} download="Manual 4 - Protel" className="service-block-one col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="inner-box">
                                <div className="icon">
                                    <span className="bi bi-filetype-pdf">
                                    </span>
                                </div>
                                <h3 className='text-dark'>Manual 4 - Protel</h3>
                                <div className="text text-dark">Com o Manual 04 você aprende a gerar os arquivos GERBER com o programa Protel.</div>
                            </div>
                        </a>
                        <a href={pdf5} download="Manual 5 - Orcad" className="service-block-one col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="inner-box">
                                <div className="icon">
                                    <span className="bi bi-filetype-pdf">
                                    </span>
                                </div>
                                <h3 className='text-dark'>Manual 5 - Orcad</h3>
                                <div className="text text-dark">Com o Manual 05 você aprende a gerar os arquivos GERBER com o programa Orcad.</div>
                            </div>
                        </a>
                        <a href={pdf6} download="Manual 6 - Pcad 2002" className="service-block-one col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="inner-box">
                                <div className="icon">
                                    <span className="bi bi-filetype-pdf">
                                    </span>
                                </div>
                                <h3 className='text-dark'>Manual 6 - Pcad 2002</h3>
                                <div className="text text-dark">Com o Manual 6 você aprende a gerar os arquivos GERBER com o programa Pcad 2002.</div>
                            </div>
                        </a>
                    </div>
                </div>
            </section>

            <SimpleContact />
        </>
    );
};

export default OurTechnology;