import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import SimpleContact from '../element/simple-contact';

const servicesbg = require('./../../assets/images/background/servicesbg.jpg');

function Services() {

    return (
        <>
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + servicesbg + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Serviços</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/#'}>Home</Link></li>
                        <li className="active">Services</li>
                    </ul>
                </div>

            </section>
            {/* <!--End Banner Section --> */}


            {/* <!-- Services Section Five --> */}
            <section className="services-section-five">
                <div className="auto-container">
                    <Tab.Container defaultActiveKey="circuito-impresso">
                        <Nav className="nav-tabs tab-btn-style-one justify-content-center">
                            <Nav.Item >
                                <Nav.Link eventKey="circuito-impresso">
                                    <div className="icon-box">
                                        <div className="icon"><span className="bi bi-motherboard-fill"></span></div>
                                        <h5>Circuito Impresso</h5>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey="layout">
                                    <div className="icon-box">
                                        <div className="icon"><span className="bi bi-grid-1x2-fill"></span></div>
                                        <h5>Layout</h5>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="circuito-impresso" className=" fadeInUp animated">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="image"><img src={require('../../assets/images/services/circuito-impresso.jpg')} alt="" /></div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="content pl-lg-4">
                                            <div className="sec-title">
                                                <div className="sub-title">Serviços</div>
                                                <h2>Circuito Impresso</h2>
                                            </div>
                                            <div className="text">
                                                Oferecemos uma ampla variedade de Circuitos Impressos, cada um adaptável às suas necessidades. Nossas opções incluem:
                                                <br></br>
                                                <br></br>
                                                • Espessuras de chapas de fibra de vidro: 0,8; 1,0; 1,2; 1,6; 2,0 e 2,4mm;
                                                <br></br>
                                                • Laminado de cobre de 17 a 70 micras;
                                                <br></br>
                                                • Acabamento com máscara anti-solda, foto imageable, estanho nas ilhas (hot air) e legenda de componentes em branco ou amarelo;
                                                <br></br>
                                                • Processos especiais como douração em contato de borda, peelable e pasta de carbono;
                                                <br></br>
                                                • Todas as placas são 100% testadas eletricamente;
                                                <br></br>
                                                • Possibilidade de entrega de PCI's individualmente, em painel vincado ou painel fresado;
                                                <br></br>
                                                • Placas com contatos e jumpers de carbono.
                                            </div>
                                            <div className="link-btn"><a href={'https://wa.me/5551994317278?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+realizar+um+or%C3%A7amento%21'} target='_blank' rel="noopener noreferrer" className="theme-btn btn-style-one"><span className="btn-title">Falar com especialista</span></a></div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="layout" className=" fadeInUp animated">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="image"><img src={require('../../assets/images/services/layout.jpg')} alt="" /></div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="content pl-lg-4">
                                            <div className="sec-title">
                                                <div className="sub-title">Serviços</div>
                                                <h2>Layout</h2>
                                            </div>
                                            <div className="text">
                                                Queremos ajudar nosso cliente em todas etapas, e para isso, contamos com profissionais layoutistas os quais poderão auxiliar na produção de seus arquivos Gerbers, entre outros:
                                                <br></br>
                                                <br></br>
                                                - Conversão de tecnologia PTH (convencional) para SMT;
                                                <br></br>
                                                - Indicação de componentes de melhor custo e tecnologia;
                                                <br></br>
                                                - Elaboração e documentação de lay-out;
                                                <br></br>
                                                - Produção de protótipos.
                                            </div>
                                            <div className="link-btn"><a href={'https://wa.me/5551994317278?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+realizar+um+or%C3%A7amento%21'} target='_blank' rel="noopener noreferrer" className="theme-btn btn-style-one"><span className="btn-title">Falar com especialista</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
            <SimpleContact />
        </>
    )
}

export default Services;