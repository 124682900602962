import React from 'react';
import { Link } from 'react-router-dom';

const aboutbg = require('./../../assets/images/background/image-11.jpg');

function About() {

    return (
        <>
            <section className="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + 'https://img.freepik.com/free-photo/old-city-view_1417-1736.jpg?w=740&t=st=1692639077~exp=1692639677~hmac=bf9b11101b92ee54ae3e23570c3290c4bf028c278d89262c0b84fa8ec0a4fb06' + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Sobre a GCI</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/'}>Home</Link></li>
                        <li className="active">Sobre a GCI</li>
                    </ul>
                </div>

            </section>

            {/* <!-- About Section Two --> */}
            <section class="about-section-two">
                <div class="auto-container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="content-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="sec-title">
                                    <div class="sub-title">Sobre a GCI</div>
                                    <h2>Nossa história</h2>
                                    <div class="text">Fundada em 2012, a GCI vem evoluindo a cada ano, sempre buscando excelência na qualidade de seus produtos e serviços. Contando com profissionais experientes no segmento da eletrônica, a empresa é capaz de englobar com qualidade todas as esferas de soluções existentes no ramo. Desta maneira, a GCI conquistou clientes em solo nacional e estrangeiro, atuando no mercado de telecomunicações, linha branca, automotivo, energia, automação industrial, automação residencial, automação comercial, eletromedicina e indústrias gerais.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="image-wrapper wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="image-one">
                                    <img src={require('../../assets/images/logo-3.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Services Section Three --> */}
            <section className="services-section-three">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <div className="sub-title">Sobre nós</div>
                        <h2>A GCI Eletrônica</h2>
                    </div>
                    <div className="row">
                        {/* <!-- Services Block One --> */}
                        <div className="service-block-one col-lg-4">
                            <div className="inner-box">
                                <div className="icon"><span className="bi bi-bullseye"></span><span className="round-shape"></span></div>
                                <h3>Missão</h3>
                                <div className="text">Fabricar placas de circuitos impressos para os diversos segmentos do mercado.</div>
                            </div>
                        </div>
                        {/* <!-- Services Block One --> */}
                        <div className="service-block-one col-lg-4">
                            <div className="inner-box">
                                <div className="icon"><span className="bi bi-eye-fill"></span><span className="round-shape"></span></div>
                                <h3>Visão</h3>
                                <div className="text">Ser a solução dos clientes na fabricação e fornecimento de Placas de Circuitos Impressos.</div>
                            </div>
                        </div>
                        {/* <!-- Services Block One --> */}
                        <div className="service-block-one col-lg-4">
                            <div className="inner-box">
                                <div className="icon"><span className="bi bi-people-fill"></span><span className="round-shape"></span></div>
                                <h3>Valores</h3>
                                <div className="text">
                                    Ética
                                    <br></br>
                                    Profissionalismo
                                    <br></br>
                                    Respeito Mútuo
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- About Section Two --> */}
            <section class="about-section-two">
                <div class="auto-container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="image-wrapper wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="image-one">
                                    <img src={require('../../assets/images/about/qualidade.jpg')} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="content-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="sec-title">
                                    <div class="sub-title">Sobre a GCI</div>
                                    <h2>Nosso foco: Qualidade</h2>
                                    <div class="text">A GCI preza pela qualidade na fabricação de seus produtos e em todos os serviços prestados. A empresa adotou como política de qualidade a satisfação total dos clientes, por meio de melhorias nos processos produtivos e de atualizações permanentes em seu quadro de profissionais, visando sempre prestar o serviço da melhor maneira possível para atender às expectativas dos clientes. A qualidade da empresa é traduzida através de um bom ambiente de trabalho, que torna viável entregas nos prazos estabelecidos, gerando satisfação para o cliente e retorno para a GCI.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- News Section --> */}
            <section className="news-section gray-bg">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <div className="sub-title">Estrutura</div>
                        <h2>Nossos Equipamentos</h2>
                    </div>
                    <div className="row">
                        {/* <!-- News Block One --> */}
                        <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><img src={require('../../assets/images/about/te-ponto-a-ponto.jpg')} alt="" /></div>
                                <div className="lower-content">
                                    <div className="category">Testes</div>
                                    <h3>Teste Elétrico Ponto a Ponto.</h3>
                                    <div className="text">A máquina de Teste Elétrico Ponto a Ponto é um dispositivo de verificação de circuitos que identifica conexões e falhas em componentes elétricos, percorrendo cada ponto de contato para assegurar a integridade do sistema.</div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- News Block One --> */}
                        <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><img src={require('../../assets/images/about/furacao-posalux.jpg')} alt="" /></div>
                                <div className="lower-content">
                                    <div className="category">Furação</div>
                                    <h3>CNC Furação - Posalux.</h3>
                                    <div className="text">A máquina CNC de Furação Posalux a Ponto é uma ferramenta de alta precisão usada para criar furos minúsculos em materiais, ideal para aplicações que requerem detalhes complexos.</div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- News Block One --> */}
                        <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <div className="inner-box">
                                <div className="image"><img src={require('../../assets/images/about/contorno-posalux.jpg')} alt="" /></div>
                                <div className="lower-content">
                                    <div className="category">Contorno</div>
                                    <h3>CNC Contorno - Posalux.</h3>
                                    <div className="text fo">A máquina CNC Contorno Posalux a Ponto é um equipamento de precisão utilizado na fabricação de joias e peças delicadas.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default About;