import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './menu'
import { useEffect } from 'react';
import { useState } from 'react';

function Header2() {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        var btn = document.querySelector('.mobile-nav-toggler');
        var closeBtn = document.querySelector('.close-btn');
        var body = document.getElementsByTagName('body')[0];

        function addFunc() {
            return body.classList.add("mobile-menu-visible");
        }
        function closeFunc() {
            return body.classList.remove("mobile-menu-visible");
        }

        btn.addEventListener('click', addFunc);
        closeBtn.addEventListener('click', closeFunc);

        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    };

    return (
        <>
            <header className={scrolled ? "main-header header-style-two fixed-header" : "main-header header-style-two"}>
                <div className="header-top-two">
                    <div className="auto-container">
                        <div className="inner">
                            <div className="top-left">
                                <ul className="contact-info">
                                    <li><a href={'mailto:comercial@gcieletronica.com.br'}><i className="far fa-envelope"></i>comercial@gcieletronica.com.br</a></li>
                                    <li><a href={'tel:+555130744750 '}><i className="far fa-phone"></i>(51) 3074-4750 </a></li>

                                    <li><a target='_blank' rel='noopener noreferrer' href={'https://www.facebook.com/gcieletronica'}><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a target='_blank' rel='noopener noreferrer' href={'https://www.linkedin.com/company/gci-eletronica-circuitos-impressos'}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a target='_blank' rel='noopener noreferrer' href={'https://www.instagram.com/gcieletronica/'}><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-upper">
                    <div className="auto-container">
                        <div className="inner-container">

                            <div className="nav-outer clearfix">

                                <div className="logo-box">
                                    <div className="logo"><Link to={'/'}><img className='header-logo' src={require('../../assets/images/logo-2.png')} alt="" /></Link></div>
                                </div>

                                <div className="mobile-nav-toggler"><span className="icon fal fa-bars"></span></div>

                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <Menu />
                                </nav>
                                <div className="link-btn"><a href={'https://wa.me/5551994317278?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+realizar+um+or%C3%A7amento%21'} target='_blank' rel="noopener noreferrer" className="theme-btn btn-style-one"><span className="btn-title">Orçar agora!</span></a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={scrolled ? "sticky-header animated slideInDown" : "sticky-header"}>
                    <div className="auto-container clearfix">

                        <div className="logo pull-left">
                            <Link to={'/'} title=""><img className='header-logo' src={require('../../assets/images/logo-3.png')} alt="" title="" /></Link>
                        </div>

                        <div style={{ margin: '1rem 0' }} className="pull-right">
                            <nav className="main-menu clearfix">
                                <Menu />
                            </nav>

                        </div>
                    </div>
                </div>

                <div className="mobile-menu">
                    <div className="menu-backdrop"></div>
                    <div className="close-btn"><span className="icon flaticon-cancel"></span></div>

                    <nav className="menu-box">
                        <div className="nav-logo"><Link to={'/'}><img src="assets/images/logo.png" alt="" title="" /></Link></div>
                        <div className="menu-outer">
                            <Menu />
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}
export default Header2;


