import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../element/contact-form';

const contactbg = require('./../../assets/images/background/contactbg.jpg');

function Contact() {

    return (
        <>
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner" >
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + contactbg + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Fale com a gente</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/'}>Home</Link></li>
                        <li className="active">Contato</li>
                    </ul>
                </div>
            </section>

            {/* <!-- Contact Section Two --> */}
            <section className="contact-section-two" >
                <div className="auto-container">
                    <div className="contact-info-area">
                        <div className="contact-info">
                            <div className="row">
                                <div className="info-column col-lg-4">
                                    <div className="icon-box">
                                        <div className="icon"><span className="bi bi-envelope-fill"></span></div>
                                        <h3>E-mail</h3>
                                        <ul>
                                            <li><Link to={'mailto:comercial@gcieletronica.com.br'}>comercial@gcieletronica.com.br</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="info-column col-lg-4">
                                    <div className="icon-box">
                                        <div className="icon"><span className="bi bi-telephone-fill"></span></div>
                                        <h3>WhatsApp e Telefone</h3>
                                        <ul>
                                            <li><a href={'https://wa.me/5551994317278?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+realizar+um+or%C3%A7amento%21'} rel="noopener noreferrer" target='_blank'>(51) 9 9431-7278</a></li>
                                            <li><a href={'tel:555130744750'}>(51) 3074.4750</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="info-column col-lg-4">
                                    <div className="icon-box">
                                        <div className="icon"><span className="bi bi-geo-alt-fill"></span></div>
                                        <h3>Nosso Endereço</h3>
                                        <ul>
                                            <li>
                                                Av. Cruzeiro, 361 - Distrito
                                                Industrial Cachoeirinha - RS
                                                CEP: 94930-615
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactForm />
                </div>
            </section>
        </>
    );
}

export default Contact;