import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const Brand1_content = [
    {
        thumb: require('../../assets/images/resource/client-1.png'),

    },
    {
        thumb: require('../../assets/images/resource/client-2.png'),

    },
    {
        thumb: require('../../assets/images/resource/client-3.png'),

    },
    {
        thumb: require('../../assets/images/resource/client-4.png'),

    },
    {
        thumb: require('../../assets/images/resource/client-5.png'),

    },
    {
        thumb: require('../../assets/images/resource/client-2.png'),

    },
]


function Brand1() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 4,
                }
            }
        ]
    };
    return (
        <>
            {/* <!-- Sponsors Section --> */}
            <section className="sponsors-section">
                <div className="container-fluid">
                    {/* <!--Sponsors Carousel--> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <Slider {...settings} className="theme_carousel">
                                {Brand1_content.map((item, id) => (
                                    <div className="slide-item" key={id}><figure className="image-box"><Link to={'/#'}><img src={item.thumb} alt="" /></Link></figure></div>
                                ))}
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
};

export default Brand1;