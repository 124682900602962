import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeSlider2 from '../element/home-slider2';
import Testimonial1 from '../element/testimonial1'
import '../../assets/css/color-2.css';

import Brand1 from '../element/brand1';
import { Nav, Tab } from 'react-bootstrap';
import ContactForm from '../element/contact-form';
import SimpleContact from '../element/simple-contact';

function Home() {

    return (
        <>
            <HomeSlider2 />

            {/* <!-- Why Choose Us Section --> */}
            <section class="why-chooseus-section">
                <div class="auto-container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="content-box">
                                <div class="sec-title">
                                    <div class="sub-title">Soluções</div>
                                    <h2>Conexão Global:<br />PCB e Logística</h2>
                                </div>
                                <Tab.Container defaultActiveKey="first">
                                    <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first"><span>Circuito Impresso</span></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third"><span>Logistica Internacional</span></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className="tabs-content">
                                        <Tab.Pane eventKey="first" className="fadeInUp animated">
                                            <div class="clearfix">
                                                <div class="image"><img style={{ width: '10rem' }} src={require('./../../assets/images/home/mini-golden-board.jpg')} alt="" /></div>
                                                <div class="text"><p>Produzimos circuitos impressos de diversos tipos para os mais variados fins e todos com uma característica em comum: alta qualidade. Estamos sempre dispostos a ajudar nosso cliente em todas etapas, dispondo também profissionais layoutistas para auxiliar em quaisquer dúvida sobre Layout.</p></div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third" className="fadeInUp animated">
                                            <div class="clearfix">
                                                <div class="image"><img style={{ width: '10rem' }} src={require('./../../assets/images/home/international-shipping.jpg')} alt="" /></div>
                                                <div class="text"><p>Cote conosco sobre frete e desembarque. Garantimos que seus produtos cheguem a qualquer lugar do país, (de forma eficiente e mais rápida possível), não importa onde esteja localizado</p></div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="image-wrapper">
                                <div class="image-one"><img src={require('./../../assets/images/home/golden-board.jpg')} alt="" /></div>
                                <div class="image-two"><img style={{width: '20rem'}} src={require('../../assets/images/home/plane.jpg')} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SimpleContact />

            {/* <!-- Testimonial Section --> */}
            {/* <Testimonial1 />

            <Brand1 /> */}

            {/* <!-- About Section Two --> */}
            <section class="about-section-two">
                <div class="auto-container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="content-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="sec-title">
                                    <div class="sub-title">Sobre a GCI</div>
                                    <h2>Nossa história</h2>
                                    <div class="text">Fundada em 2012, a GCI vem evoluindo a cada ano, sempre buscando excelência na qualidade de seus produtos e serviços. Contando com profissionais experientes no segmento da eletrônica, a empresa é capaz de englobar com qualidade todas as esferas de soluções existentes no ramo. Desta maneira, a GCI conquistou clientes em solo nacional e estrangeiro, atuando no mercado de telecomunicações, linha branca, automotivo, energia, automação industrial, automação residencial, automação comercial, eletromedicina e indústrias gerais.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="image-wrapper wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="image-one">
                                    <img src={require('../../assets/images/logo-3.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-section-two" >
                <div className="auto-container">
                    <ContactForm />
                </div>
            </section>
        </>
    )
}

export default Home;