import React from 'react';
import { useState } from 'react';
import api from '../../services/api';
import toast from 'react-hot-toast';

const ourtechnologybg = require('./../../assets/images/background/ourtechnologybg.jpg');

export default function SimpleContact() {

    const [email, setEmail] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handleSubmitMail = (e) => {
        e.preventDefault();

        setIsSending(true);

        let payload = {
            email
        };

        const sending_mail = async () => {
            const response = api.post('simple_contact.php', payload);
            return response;
        };

        const call_send = sending_mail();

        toast.promise(call_send, {
            loading: 'Enviando mensagem...',
            success: 'Sua mensagem foi enviada com sucesso!',
            error: 'Houve um erro ao tentar enviar sua mensagem!',
        },
            {
                success: {
                    duration: 3000,
                },
            },
            {
                error: {
                    duration: 3000,
                },
            }
        );

        setEmail('');
        setIsSending(false);
    };

    return (
        <section className="cta-section" >
            <div className="auto-container">
                <div className="wrapper-box" style={{ backgroundImage: "url(" + ourtechnologybg + ")" }}>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="sec-title light">
                                <div className="sub-title">Sua oportunidade</div>
                                <h2>Entre em contato <br />com nossos especialistas</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={handleSubmitMail}>
                                <input type="email" placeholder="Insira aqui seu melhor email para entrarmos em contato..." value={email} onChange={e => setEmail(e.target.value)} required />
                                <button disabled={isSending} className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title"><i className="fal fa-envelope"></i>Enviar</span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}