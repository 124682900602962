// import React from 'react';
import React from 'react';
import Router from './markup/router';
import './assets/css/bootstrap.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/color.css'
import './App.css'

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
};

export default App;
