import React, { useEffect } from 'react';
import { useState } from 'react';
import api from '../../services/api';
import ReactInputMask from 'react-input-mask';
import toast from 'react-hot-toast';

export default function ContactForm() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState(null);
    const [fileText, setFileText] = useState('Escolher arquivo');
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        if (file) {
            setFileText(file.name);
        } else {
            setFileText('Escolher arquivo');
        };

    }, [file]);

    const handleFileChange = (e) => {
        let temp_file = e.target.files[0];

        if (temp_file && temp_file.size > 10485760) {
            toast.error('O arquivo submetido deve conter no máximo 10mb. Tente outro!');
            setFile(null);
        } else {
            setFile(temp_file);
        };
    };

    const handleSubmitMail = (e) => {
        e.preventDefault();

        setIsSending(true);

        let temp_name = (name.split(' '))[0];

        temp_name = temp_name.charAt(0).toUpperCase() + temp_name.slice(1);

        const formData = new FormData();
        formData.append('name', temp_name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('file', file); // Inclua o arquivo na formData

        const sending_mail = async () => {
            const response = api.post('main_contact.php', formData);
            return response;
        };

        const call_send = sending_mail();

        toast.promise(call_send, {
            loading: 'Enviando mensagem...',
            success: 'Sua mensagem foi enviada com sucesso!',
            error: 'Houve um erro ao tentar enviar sua mensagem!',
        },
            {
                success: {
                    duration: 3000,
                },
            },
            {
                error: {
                    duration: 3000,
                },
            }
        );
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setFile(null);
        setIsSending(false);

    };

    return (
        <div className="contact-form-area">
            <div className="sec-title text-center">
                <div className="sub-title">Contato</div>
                <h2>Tire suas dúvidas!</h2>
            </div>
            {/* <!-- Contact Form--> */}
            <div className="contact-form">
                <form onSubmit={handleSubmitMail}>
                    <div className="row clearfix">
                        <div className="col-md-4 form-group">
                            <label htmlFor="name">Seu nome</label>
                            <input type="text" name="username" id="name" placeholder="Insira seu nome aqui..." required value={name} onChange={e => setName(e.target.value)} />
                            <i className="fas fa-user"></i>
                        </div>

                        <div className="col-md-4 form-group">
                            <label htmlFor="email">Seu melhor email</label>
                            <input type="email" name="email" id="email" placeholder="Insira seu melhor email aqui..." required value={email} onChange={e => setEmail(e.target.value)} />
                            <i className="fas fa-envelope"></i>
                        </div>

                        <div className="col-md-4 form-group">
                            <label htmlFor="phone">Seu telefone</label>
                            <ReactInputMask
                                mask='(99) 9999-9999'
                                type="text" name="phone" id="phone" placeholder="Insira seu telefone..." required value={phone} onChange={e => setPhone(e.target.value)}>
                            </ReactInputMask>
                            <i className="fas fa-solid fa-phone"></i>
                        </div>

                        <div className="col-md-12 form-group">
                            <label htmlFor="message">Insira sua mensagem</label>
                            <textarea required name="message" id="message" placeholder="Insira sua mensagem aqui..." value={message} onChange={e => setMessage(e.target.value)}></textarea>
                            <i className="fas fa-edit"></i>
                        </div>
                        <div className="col-md-6 form-group">
                            <input type="file" className="custom-file-input d-none" id="customFile" onChange={handleFileChange} accept=".zip,.rar" />
                            <label className="custom-file-label w-100 mb-0" for="customFile">{fileText}</label>
                            <small className="form-text text-muted">
                                Antes de enviar seu arquivo, lembre-se de compactá-lo. Extensões aceitas: .zip e .rar. Tamanho máximo: 10mb.
                            </small>
                        </div>

                        <div className="col-md-12 form-group">
                            <button disabled={isSending} className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">Enviar</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};