import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const bgimg7 = require('./../../assets/images/background/image-7.jpg');

const Testimonial1_content = [
    {
        thumb: require('./../../assets/images/resource/thumb-1.jpg'),
        author: 'Alexer Valvin',
        designation: 'Founder, Coxer IT',
        title: 'Design Quality',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    },
    {
        thumb: require('./../../assets/images/resource/thumb-2.jpg'),
        author: 'Alexer Valvin',
        designation: 'Founder, Coxer IT',
        title: 'Design Quality',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    },
    {
        thumb: require('./../../assets/images/resource/thumb-3.jpg'),
        author: 'Alexer Valvin',
        designation: 'Founder, Coxer IT',
        title: 'Design Quality',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    },
    {
        thumb: require('./../../assets/images/resource/thumb-4.jpg'),
        author: 'Alexer Valvin',
        designation: 'Founder, Coxer IT',
        title: 'Design Quality',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    },
    {
        thumb: require('./../../assets/images/resource/thumb-5.jpg'),
        author: 'Alexer Valvin',
        designation: 'Founder, Coxer IT',
        title: 'Design Quality',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    },
    {
        thumb: require('./../../assets/images/resource/thumb-6.jpg'),
        author: 'Alexer Valvin',
        designation: 'Founder, Coxer IT',
        title: 'Design Quality',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

    },
]


class Testimonial1 extends Component {


    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 1500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1536,
                    settings: {
                        slidesToShow: 4,
                    }
                }
            ]
        };
        return (
            <>
                <section className="testimonial-section" style={{ backgroundImage: "url(" + bgimg7 + ")" }}>
                    <div className="auto-container">
                        <div className="sec-title">
                            <div className="sub-title">Testimonials</div>
                            <h2>Clients Feedback</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <Slider {...settings} className="theme_carousel">
                                    {Testimonial1_content.map((item, id) => (
                                        <div className="slide-item" key={id}>
                                            {/* <!-- Testimonial-block --> */}
                                            <div className="testimonial-block">
                                                <div className="inner-box">
                                                    <div className="icon"><span className="fal fa-star"></span></div>
                                                    <div className="quote"><span className="fal fa-quote-left"></span></div>
                                                    <h4>{item.title}</h4>
                                                    <div className="text">{item.description} </div>
                                                </div>
                                                <div className="author-info">
                                                    <div className="thumb"><img src={item.thumb} alt="" /></div>
                                                    <h4>{item.author}</h4>
                                                    <div className="designation">{item.designation}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
export default Testimonial1;