import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/about'
import Contact from './pages/contact'
import BackToTop from './layout/backToTop'
import Layout from './layout/layout';
import ScrollTop from './element/scrollTop';
import Quote from './pages/quote';
import OurTechnology from './pages/our-technology';
import Home from './pages/home';
import Products from './pages/products';
import Services from './pages/services';

function Router() {

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path='/' exact element={<Home />} />
                    <Route path='/empresa' exact element={<About />} />
                    <Route path='/produtos' element={<Products />} />
                    <Route path='/servicos' element={<Services />} />
                    <Route path='/nossa-tecnologia' element={<OurTechnology />} />
                    <Route path='/orcamento' element={<Quote />} />
                    <Route path='/contato' element={<Contact />} />
                    <Route path='*' exact element={<Home />} />
                </Routes>

                <ScrollTop />

                <BackToTop />
            </Layout>
        </BrowserRouter>
    )
};

export default Router;