import React from 'react';
import Header2 from "./header2";
import Footer from './footer';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';

const WhatsappButton = () => {

    return (
        <a href="https://wa.me/5551994317278?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+realizar+um+or%C3%A7amento%21" className="float" rel="noopener noreferrer" target="_blank">
            <i className="bi bi-whatsapp my-float" ></i>
        </a>
    )
};

export default function Layout({ children }) {

    return (
        <div className="page-wrapper">
            <Header2 />
            {children}
            <Footer />
            <Toaster/>
            <WhatsappButton />
        </div>
    )
}