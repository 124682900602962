import React from 'react';
import { Link } from 'react-router-dom';
import IsActive from '../element/isActive';

function Menu() {
    const menuItems = [
        { path: '/', label: 'Home' },
        { path: '/empresa', label: 'Empresa' },
        { path: '/servicos', label: 'Serviços' },
        { path: '/produtos', label: 'Produtos' },
        { path: '/nossa-tecnologia', label: 'Nossa Tecnologia' },
        { path: '/orcamento', label: 'Orçamento' },
        { path: '/contato', label: 'Contato' },
    ];

    return (
        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
            <ul className="navigation clearfix">
                {menuItems.map((menuItem, index) => (
                    <li key={index}>
                        <Link to={menuItem.path}><IsActive route={menuItem.path}>{menuItem.label}</IsActive></Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Menu;