import React, { useState } from 'react';
import toast from 'react-hot-toast';
import ReactInputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { useEffect, useRef } from 'react';

const quotebg = require('./../../assets/images/background/quote.jpg');

function Quote() {

    const [file, setFile] = useState(null);
    const [fileText, setFileText] = useState('Escolher arquivo');

    const formRef = useRef(null);

    useEffect(() => {
        if (file) {
            setFileText(file.name);
        } else {
            setFileText('Escolher arquivo');
        };

    }, [file]);

    const handleFileChange = (e) => {
        let temp_file = e.target.files[0];

        if (temp_file && temp_file.size > 10485760) {
            toast.error('O arquivo submetido deve conter no máximo 10mb. Tente outro!');
            setFile(null);
        } else {
            setFile(temp_file);
        };
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const sending_mail = async () => {
            const response = api.post('quote_message.php', formData);
            return response;
        };

        const call_send = sending_mail();

        toast.promise(call_send, {
            loading: 'Enviando mensagem...',
            success: 'Sua mensagem foi enviada com sucesso!',
            error: 'Houve um erro ao tentar enviar sua mensagem!',
        },
            {
                success: {
                    duration: 3000,
                },
            },
            {
                error: {
                    duration: 3000,
                },
            }
        );

        handleReset();
    };

    return (
        <>
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner" >
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + quotebg + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Fazer cotação</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/#'}>Home</Link></li>
                        <li className="active">Orçamento</li>
                    </ul>
                </div>
            </section>

            {/* <!-- Contact Section Two --> */}
            <section className="contact-section-two" >
                <div className="auto-container">
                    <div className="contact-form-area">
                        <div className="contact-form">
                            <form ref={formRef} onSubmit={handleFormSubmit} id="contact-form">
                                <div className="row clearfix">
                                    <div className="col-12 sec-title text-center">
                                        <div className="sub-title">Orçamento</div>
                                        <h2>Você e seu negócio</h2>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="name">Seu Nome</label>
                                        <input type="text" name="name" id="name" placeholder="Insira aqui seu nome..." required />
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="company">Empresa *</label>
                                        <input type="text" name="company" id="company" placeholder="Insira aqui o nome de sua empresa..." required />
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="cnpj">CNPJ *</label>
                                        <ReactInputMask
                                            mask='99.999.999/9999-99'
                                            type="text" name="cnpj" id="cnpj" placeholder="Insira aqui seu CNPJ..." required>
                                        </ReactInputMask>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="email">E-mail *</label>
                                        <input type="email" name="email" id="email" placeholder="Insira aqui seu email..." required />
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="phone">Telefone *</label>
                                        <ReactInputMask
                                            mask='(99) 9 9999-9999'
                                            type="text" name="phone" id="phone" placeholder="Insira aqui seu melhor telefone..." required>
                                        </ReactInputMask>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="address">Endereço</label>
                                        <input type="text" name="address" id="address" placeholder="Insira aqui o endereço de sua empresa..." />
                                    </div>

                                    <div className="col-12 sec-title text-center">
                                        <div className="sub-title">Orçamento</div>
                                        <h2>Detalhes do Produto</h2>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="model">Modelo</label>
                                        <input type="text" name="model" id="model" placeholder="Insira o nome ou código do Modelo que deseja orçar..." required="" />
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="dimension">Dimensões</label>
                                        <div class="row">
                                            <div class="col">
                                                <input name="height" id="height" type="text" class="form-control" placeholder="Altura" />
                                            </div>

                                            <div class="col">
                                                <input name="width" id="width" type="text" class="form-control" placeholder="Largura" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="quantity">Quantidade</label>
                                        <input type="text" name="quantity" id="quantity" placeholder="Insira aqui a quantidade desejada...." required="" />
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label class="mr-sm-2" for="type-of-circuit">Tipo de Circuito</label>
                                        <select class="custom-select mr-sm-2" id="type-of-circuit" name="type-of-circuit">
                                            <option selected value=''> - Definir tipo de Circuito - </option>
                                            <option value="Face Simples">Face Simples</option>
                                            <option value="Dupla face">Dupla face</option>
                                            <option value="Multilayer">Multilayer</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label class="mr-sm-2" for="copper-thickness">Espessura do Cobre</label>
                                        <select class="custom-select mr-sm-2" id="copper-thickness" name="copper-thickness">
                                            <option selected value=''> - Definir Espessura do Cobre - </option>
                                            <option value="1/2 OZ">1/2 OZ</option>
                                            <option value="1 OZ">1 OZ</option>
                                            <option value="2 OZ">2 OZ</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label class="mr-sm-2" for="board-thickness">Espessura da Placa (mm)</label>
                                        <select class="custom-select mr-sm-2" id="board-thickness" name="board-thickness">
                                            <option selected value=''> - Definir Espessura da Placa - </option>
                                            <option value="0,8">0,8</option>
                                            <option value="1,0">1,0</option>
                                            <option value="1,2">1,2</option>
                                            <option value="1,6">1,6</option>
                                            <option value="2,0">2,0</option>
                                            <option value="2,4">2,4</option>
                                            <option value="Outro">Outro</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label class="mr-sm-2" for="surface-treatment">Tratamento de superfície</label>
                                        <select class="custom-select mr-sm-2" id="surface-treatment" name="surface-treatment">
                                            <option selected value=''> - Definir Tratamento de Superficie</option>
                                            <option value="HAL">HAL</option>
                                            <option value="OSP">OSP</option>
                                            <option value="ENIG">ENIG</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="smaller-track">Menor Pista</label>
                                        <input type="text" name="smaller-track" id="smaller-track" placeholder="Insira aqui o tamanho da menor pista (mils)" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="smaller-isolation">Menor Isolação</label>
                                        <input type="text" name="smaller-isolation" id="smaller-isolation" placeholder="Insira aqui o tamanho da menor isolação (mils)" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="smaller-hole">Diâmetro do Menor furo *</label>
                                        <input type="text" name="smaller-hole" id="smaller-hole" placeholder="Insira aqui o tamanho do menor diâmetro de furo (mils)" />
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label class="mr-sm-2" for="programming">Programação</label>
                                        <select class="custom-select mr-sm-2" id="programming" name="programming">
                                            <option value="Sim" selected>Sim</option>
                                            <option value="Não">Não</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label class="mr-sm-2" for="other-characteristics">Outras Características</label>
                                        <select class="custom-select mr-sm-2" id="other-characteristics" name="other-characteristics">
                                            <option selected value=''> - Definir outras características - </option>
                                            <option value="Conector Dourado">Conector Dourado</option>
                                            <option value="Solder-out">Solder-out</option>
                                            <option value="Pasta de carbono">Pasta de carbono</option>
                                            <option value="Furos plugados">Furos plugados</option>
                                            <option value="SMD">SMD</option>
                                            <option value="BGA">BGA</option>
                                        </select>
                                    </div>

                                    <div className="col-12 sec-title text-center">
                                        <div className="sub-title">Orçamento</div>
                                        <h2>Upload e Comentários</h2>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label htmlFor="message">Insira sua mensagem</label>
                                        <textarea name="message" id="message" placeholder="Insira aqui sua mensagem..."></textarea>
                                        <i className="fas fa-edit"></i>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <input type="file" className="custom-file-input d-none" id="file" name='file' onChange={handleFileChange} accept=".zip,.rar" />
                                        <label className="custom-file-label w-100 mb-0" for="file">{fileText}</label>
                                        <small className="form-text text-muted">
                                            Antes de enviar seu arquivo, lembre-se de compactá-lo. Extensões aceitas: .zip e .rar. Tamanho máximo: 10mb.
                                        </small>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">Solicitar orçamento</span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Quote;