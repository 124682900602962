import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

const products_list = [
    {
        id: 1,
        name: "Placas FR4",
        face_simples: true,
        category: 'Face Simples',
        imgUrl: require('./../../assets/images/products/face_simples.jpg'),
        tech_specs: [
            {
                title: 'Espessuras',
                data: "0.8mm, 1.0mm, 1.2mm, 1.6mm, 2.0mm e 2.4mm."
            },
            {
                title: 'Tratamento Superficial',
                data: "OSP, HAL Sn/Pb, HAL LEAD FREE e ENIG."
            },
        ],
        text1: "É o material de PCB mais popular entre fabricantes e designers, adequado para criar várias camadas de PCB, tanto simples quanto multicamadas. Além disso, lida bem com aplicações que exigem flexibilidade ou flexão constante, sendo essencial para dispositivos como acessórios Bluetooth e conectores USB.",
    },
    {
        id: 2,
        name: "Placas CEM-1",
        face_simples: true,
        category: 'Face Simples',
        imgUrl: require('./../../assets/images/products/face_simples.jpg'),
        tech_specs: [
            {
                title: 'Espessuras',
                data: "1.6mm."
            },
            {
                title: 'Tratamento Superficial',
                data: "OSP, HAL Sn/Pb, HAL LEAD FREE e ENIG."
            },
        ],
        text1: "Representa uma alternativa de baixo custo com características elétricas e mecânicas semelhantes à PCB FR4, além de serem mais leves em comparação com outras placas de circuito impresso.",
    },
    {
        id: 3,
        name: "Placas MCPCB",
        MCPCB: true,
        category: 'MCPCB',
        imgUrl: require('./../../assets/images/products/metal_base_pcb.jpg'),
        tech_specs: [
            {
                title: 'Espessuras',
                data: "1.5mm."
            },
            {
                title: 'Categorias',
                data: "1w/Mk, 2w/Mk e 3w/Mk."
            },
            {
                title: 'Tratamento Superficial',
                data: "OSP, HAL Sn/Pb e HAL LEAD FREE."
            },

        ],
        text1: "MCPCB utiliza um substrato especial que reforça a resistência de dispositivos operando em altas temperaturas. Além disso, também se destaca pela sua solidez e robustez em relação à capacidade mecânica.",
    },
    {
        id: 4,
        name: "Placas Dupla Face",
        dupla_face: true,
        category: 'Dupla Face',
        imgUrl: require('./../../assets/images/products/face_dupla.jpg'),
        tech_specs: [
            {
                title: 'Espessuras',
                data: "0.8mm, 1.0mm, 1.2mm, 1.6mm, 2.0mm e 2.4mm"
            },
            {
                title: 'Tratamento Superficial',
                data: "OSP, HAL Sn/Pb, HAL LEAD FREE e ENIG."
            },
        ],
        text1: "Oferecem aos designers maior flexibilidade, permitindo uma densidade de circuitos ampliada a custos relativamente mais baixos. São ideais para projetos de complexidade intermediária, destacando-se pela redução do tamanho da placa.",
    },
    {
        id: 5,
        name: "Placas Multilayer",
        multilayer: true,
        category: 'Multilayer',
        imgUrl: require('./../../assets/images/products/multilayer.jpg'),
        tech_specs: [
            {
                title: 'Número de Layers',
                data: "4 a 12 camadas."
            },
            {
                title: 'Tratamento Superficial',
                data: "OSP, HAL Sn/Pb, HAL LEAD FREE e ENIG."
            },
        ],
        text1: "Oferecem uma solução eletrônica avançada com um design compacto, graças às técnicas de fabricação de ponta. Essas placas são conhecidas por sua alta durabilidade e versatilidade, permitindo a integração de múltiplas funções em um único componente.",
    },
    {
        id: 6,
        name: "Flex Rigid - PCB",
        flex_rigid_PCB: true,
        category: 'PCB',
        imgUrl: require('./../../assets/images/products/flex_rigid_pcb.jpg'),
        text1: "Projetada para se adaptar a qualquer espaço, é uma placa adequada para dispositivos menores devido à sua capacidade de ser dobrada. Permite também a montagem superficial em ambos os lados, aumentando sua versatilidade de uso.",
    },
];

const FILTER_DEFS = {
    face_simples: image => image.face_simples,
    dupla_face: image => image.dupla_face,
    MCPCB: image => image.MCPCB,
    multilayer: image => image.multilayer,
    flex_rigid_PCB: image => image.flex_rigid_PCB,
    ALL: image => image
};

const PortfolioFilter1 = () => {
    const [state, setState] = useState({
        list: products_list,
        filterKey: "ALL"
    });

    const [activeItem, setActiveItem] = useState({});

    const [show, setShow] = useState(false);

    const handleShow = (obj) => {
        setShow(true);
        setActiveItem(obj);
    };
    const handleClose = () => {
        setShow(false);
        setActiveItem({});
    };

    const { list, filterKey } = state;

    const filteredList = list.filter(FILTER_DEFS[filterKey]);

    return (
        <>
            <section className="gallery-section">
                <div className="sortable-masonry">
                    <div className="auto-container">
                        <div className="row m-0 justify-content-md-between align-items-center">
                            <div className="sec-title">
                                <div className="sub-title">Produtos</div>
                                <h2>PCBs sob medida</h2>
                            </div>
                            {/* <!--Filter--> */}
                            <div className="filters text-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <ul className="filter-tabs filter-btns">
                                    <li className="active filter" onClick={() => setState({ ...state, filterKey: "ALL" })}><span>Todos</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "face_simples" })}><span>Face Simples</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "MCPCB" })}><span>MCPCB</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "dupla_face" })}><span>Dupla Face</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "multilayer" })}><span>Multilayer</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "flex_rigid_PCB" })}><span>Flex Rigid - PCB</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="auto-container">
                        <div className="items-container row">
                            {filteredList.map(image => (
                                <div onClick={() => handleShow(image)} className="col-lg-4 col-md-6 gallery-block-one" data-toggle="modal" data-target="#exampleModal" key={image.id} style={{ cursor: 'pointer' }}>
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={image.imgUrl} alt="" />
                                        </div>
                                        <div className="caption-title">
                                            <h5>{image.category}</h5>
                                            <h3>{image.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="flex-column">
                    <p class="card-subtitle text-muted">{activeItem.category}</p>
                    <Modal.Title>{activeItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh', overflow: 'scroll' }}>

                    <Image src={activeItem.imgUrl} rounded />
                    {
                        activeItem.tech_specs &&
                        <div className="card my-3">
                            <div className="card-header font-22">
                                <h3 class="h5">Especificações Técnicas</h3>
                            </div>
                            <div className="card-body">
                                <table className="cart-table">
                                    <tbody>
                                        {
                                            activeItem.tech_specs.map(obj => {
                                                return (
                                                    <tr>
                                                        <td style={{ width: '50%', padding: '1rem', fontSize: '.8rem' }}><strong>{obj.title}:</strong></td>
                                                        <td style={{ fontSize: '.8rem' }}>{obj.data}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <p className="mt-3">
                        {activeItem.text1}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn-sm btn-light" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" className="btn-sm btn-success" href="https://wa.me/5551994317278?text=Ol%C3%A1%2C+tudo+bem%3F+Gostaria+de+realizar+um+or%C3%A7amento%21" rel="noopener noreferrer" target="_blank">
                        Entrar em contato com especialista
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PortfolioFilter1;
