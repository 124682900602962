import React from 'react';
import { useLocation } from 'react-router-dom';


export default function IsActive({ route, children }) {

    const { pathname } = useLocation();

    if (pathname === route) {
        return (
            <span style={{ color: '#193983' }}>
                {children}
            </span>
        )
    } else {
        return children
    };
}