import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../../assets/css/slider.css';

const slides = [
    {
        title: 'GCI Eletrônica',
        title1: "Excelência em",
        title2: "circuitos impressos",
        btnService: "Conheça nossos serviços",
        image: require('./../../assets/images/home/board.jpg'),
    },
    {
        title: 'Atendemos todo o Brasil',
        title1: "Cote conosco",
        title2: "frete e desembarque",
        btnService: "Conheça nossos produtos",
        image: require('./../../assets/images/home/plane.jpg'),
    },
];


function HomeSlider2() {

    return (
        <>
            <Slider infinite autoplay={5000} touchDisabled className="slider-wrapper">
                {slides.map((slide, index) =>
                    <div key={index} className="slider-content" style={{ background: `url('${slide.image}') no-repeat center center` }}>
                        <div className="inner text-center inner-2">
                            <h1>{slide.title}</h1>
                            <h2>{slide.title1} <span>{slide.title2} </span></h2>
                        </div>
                    </div>)}
            </Slider>
        </>
    )
};

export default HomeSlider2;