import React from 'react';
import { Link } from 'react-router-dom';
import PortfolioFilter1 from '../element/portfolio-filter1'

const productsbg = require('./../../assets/images/background/productsbg.jpg');

function Products() {

    return (
        <>
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + productsbg + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Produtos</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/'}>Home</Link></li>
                        <li className="active">Produtos</li>
                    </ul>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!-- Gallery Section --> */}
            <PortfolioFilter1 />
        </>
    )
};

export default Products;