import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="main-footer">
            <div className="auto-container">
                <div className="widgets-section">
                    <div className="row clearfix">
                        <div className="column col-lg-4">
                            <div className="footer-widget logo-widget">
                                <div className="widget-content">
                                    <div className="footer-logo">
                                        <Link to="/"><img className="" src={require('../../assets/images/logo-3.png')} alt="" /></Link>
                                    </div>
                                    <div className="text">Sempre buscando excelência na qualidade de seus produtos e serviços.
                                    </div>
                                    <ul className="social-links clearfix">
                                        <li><Link to={'https://www.facebook.com/gcieletronica'} target='_blank' rel='noopener noreferrer'><span className="fab fa-facebook-f"></span></Link></li>
                                        <li><Link to={'https://www.linkedin.com/company/gci-eletronica-circuitos-impressos'} target='_blank' rel='noopener noreferrer'><span className="fab fa-linkedin"></span></Link></li>
                                        <li><Link to={'https://www.instagram.com/gcieletronica/'} target='_blank' rel='noopener noreferrer'><span className="fab fa-instagram"></span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="column col-lg-4">
                            <div className="footer-widget links-widget">
                                <div className="widget-content">
                                    <h3>Acesso rápido</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ul>
                                                <li><Link to={'/'}>Home</Link></li>
                                                <li><Link to={'/empresa'}>Empresa</Link></li>
                                                <li><Link to={'/servicos'}>Serviços</Link></li>
                                                <li><Link to={'/produtos'}>Produtos</Link></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul>
                                                <li><Link to={'/nossa-tecnologia'}>Nossa Tecnologia</Link></li>
                                                <li><Link to={'/orcamento'}>Orçamento</Link></li>
                                                <li><Link to={'/contato'}>Contato</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column col-lg-4">
                            <div className="footer-widget links-widget">
                                <div className="widget-content">
                                    <h3 className='align-right'>Contato</h3>
                                    <div className="row">
                                        <div className="col-12">
                                            <p >
                                                <Link className='link-contact' to={'tel:555130744750'}>
                                                    Telefone: (51) 3074-4750
                                                </Link>
                                                <br></br>
                                                <Link className='link-contact' to={'mailto:comercial@gcieletronica.com.br'}>
                                                    comercial@gcieletronica.com.br
                                                </Link>
                                                <br></br>
                                                Av. Cruzeiro, 361 - Bairro Distrito
                                                <br></br>
                                                Industrial Cachoeirinha - RS
                                                <br></br>
                                                CEP: 94930-615
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="auto-container">
                <div className="footer-bottom">
                    <a target='_blank' rel='noopener noreferrer' href='https://pixellb.com.br/'>
                        <div className="copyright">
                            Desenvolvido por PixelLab - {currentYear}
                        </div>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;